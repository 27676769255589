import React, { lazy, Suspense, Fragment, useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import AdminLayout from "src/layouts/AdminLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/pages/Error404View"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView"))
  },
  {
    exact: true,
    path: "/login-unprotected",
    component: lazy(() => import("src/views/auth/LoginView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    component: lazy(() => import("src/views/auth/RegisterView"))
  },
  {
    exact: true,
    path: "/register-unprotected",
    component: lazy(() => import("src/views/auth/RegisterView"))
  },

  {
    path: "/",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/dashboard",
        component: lazy(() => import("src/views/Dashboard"))
      },
      {
        exact: true,
        path: "/reports",
        component: lazy(() =>
          import("src/views/reports/Screens/ReportList/index")
        )
      },
      {
        exact: true,
        path: "/hideview",
        component: lazy(() =>
          import("src/views/reports/Screens/EditReport/index")
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const DelayedFallback = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 30000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <>{show && <LoadingScreen />}</>;
};

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
