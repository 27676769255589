import { SpinnerScreen } from "finezza-ui-component";
import React from "react";
import { useSelector } from "react-redux";

function Spinner({ open }) {
  const { logo } = useSelector(state => state.logo);

  return (
    <SpinnerScreen
      open={open}
      src={
        logo && logo.loadingLogo
          ? `data:image/png;base64, ${logo.loadingLogo}`
          : undefined
      }
    />
  );
}
export default Spinner;
