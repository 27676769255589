import { LoadingScreen } from "finezza-ui-component";
import React from "react";
import { useSelector } from "react-redux";

function Loader() {
  const { logo } = useSelector(state => state.logo);

  return (
    <LoadingScreen
      src={
        logo && logo.loadingLogo
          ? `data:image/png;base64, ${logo.loadingLogo}`
          : undefined
      }
    />
  );
}

export default Loader;
