import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { THEMES } from "src/constants";
import Account from "./Account";
import Settings from "./Settings";
import Logout from "./Logout";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  image: { height: 50, background: "transparent" }
}));

function TopBar({ className, onMobileNavOpen, isMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const { logo } = useSelector(state => state.logo);
  const handleOpen = () => {
    onMobileNavOpen();
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={clsx(
            classes.menuButton,
            isMobileNavOpen ? classes.menuOpen : ""
          )}
          color="inherit"
          onClick={() => handleOpen()}
        >
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
        <Hidden mdDown>
          <RouterLink to="/"> </RouterLink>
        </Hidden>
        <Box p={1}>
          {logo && logo.hamburgerLogo ? (
            <img
              alt="Logo"
              width={160}
              height={50}
              src={`data:image/png;base64, ${logo.hamburgerLogo}`}
            />
          ) : (
            <img
              alt="Logo"
              width={160}
              height={50}
              src={"/images/Glasslogo-Transparent.png"}
            />
          )}
        </Box>

        <Box ml={2} flexGrow={1} />
        <Settings />
        <Box ml={2} />
        <Logout />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
