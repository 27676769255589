export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN"
};

export const message = {
  apply: "Filter applied",
  reset: "Filter reset",
  inflowAdd: "Inflow added",
  outflowAdd: "Outflow added",
  waiverAdded: "Waiver add in a while",
  wrong: "Something went wrong",
  tagAdded: "Tag Added",
  accessDenied: "Access Denied",
  waiverDate: "Please Select Date"
};

export const permission = {};

export const confirmButtonText = {
  update: "Are you sure you want to update ?",
  delete: "Are you sure you want to delete ?",
  clear: "Are you sure you want to clear ?"
};

export const assigneeDetails = {
  collectionAssignee: "Nelin Istnl",
  secondaryCollectionAssignee: "Alex Joy",
  relationshipManager: "Meyon wisk",
  collectionManager: "Kovp Oiy"
};

// export const nachTracker = {
//   submitSuccess: "Successfully submitted for NACH registration"
// };
export const ENTER_KEY_CODE = "13";
export const error = "ERROR";
