import React, { Component } from "react";
import ErrorUI from "src/views/pages/ErrorUI";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, key: false };
    this.handleUiScreen = this.handleUiScreen.bind(this);
  }

  handleUiScreen = () => {
    this.setState({ hasError: false });
  };

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ISDEV === "false") {
      this.setState({ hasError: true });
    }
  }

  componentDidMount(prevProps, prevState) {
    this.setState({ key: !this.state.key });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorUI handleUiScreen={this.handleUiScreen} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
