import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import { IconButton, SvgIcon, Tooltip, makeStyles } from "@material-ui/core";
import API from "src/services/axioshelper";
import SpinnerScreen from "src/components/SpinnerScreen";
import { logout } from "src/actions/accountActions";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { LogOut } from "react-feather";

const useStyles = makeStyles(theme => ({}));

function Logout() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSpinner, setOpenSpinner] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    setOpenSpinner(true);
    try {
      API({
        method: "post",
        url: "api/logout"
      })
        .then(async response => {
          await dispatch(logout());
          setOpenSpinner(false);
          history.push("/");
        })
        .catch(err => {
          setOpenSpinner(false);
          if (err.response !== undefined)
            enqueueSnackbar(err.response.data.message, {
              variant: "error"
            });
          else {
            enqueueSnackbar("Something Went Wrong", {
              variant: "error"
            });
          }
        });
    } catch (error) {
      enqueueSnackbar("Unable to logout", {
        variant: "error"
      });
    }
  };

  return (
    <>
      <SpinnerScreen open={openSpinner} />
      <Tooltip title="Logout">
        <IconButton color="inherit" onClick={handleLogout} ref={ref}>
          <SvgIcon fontSize="small">
            <LogOut />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Logout;
