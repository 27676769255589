import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Auth from "src/components/Auth";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import ScrollReset from "src/components/ScrollReset";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import Routes from "src/Routes";
import ErrorBoundary from "./components/ErrorBoundary";
import SpinnerScreen from "src/components/SpinnerScreen";
import API from "src/utils/axiosHelper";
import { useSelector, useDispatch } from "react-redux";

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%"
      },
      body: {
        height: "100%",
        width: "100%"
      },
      "#root": {
        height: "100%",
        width: "100%"
      }
    }
  })
);

function getFaviconEl() {
  return document.getElementById("favicon");
}
function App() {
  useStyles();
  const spinner = useSelector(state => state.spinner);
  const logo = useSelector(state => state.logo);
  const dispatch = useDispatch();
  const { settings } = useSettings();

  useEffect(() => {
    dispatch({
      type: "FETCHING_LOGO",
      payload: ""
    });
    API({
      method: "get",
      url: `/api/logo/1`
    })
      .then(res => {
        dispatch({
          type: "FETCHED_LOGO",
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: "ERROR_LOGO",
          payload: ""
        });
      });
  }, []);

  useEffect(() => {
    if (logo.isDone) {
      const favicon = getFaviconEl();

      if (
        logo.logo.tabLogo !== undefined &&
        logo.logo.tabLogo !== "" &&
        logo.logo.tabLogo !== null
      ) {
        favicon.href = `data:image/png;base64, ${logo.logo.tabLogo}`;
      } else {
        favicon.href = "/favicon.png";
      }
    }
  }, [logo.isDone]);

  if (!logo.isDone) {
    return null;
  }

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={2}>
            <ErrorBoundary history={history}>
              <Router history={history}>
                <SpinnerScreen open={spinner}></SpinnerScreen>

                <Auth>
                  <ScrollReset />
                  <GoogleAnalytics />
                  <Routes />
                </Auth>
              </Router>
            </ErrorBoundary>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
