/* eslint-disable no-param-reassign */
import produce from "immer";

const initialState = {
  logo: {},
  isDone: false
};

const logoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCHING_LOGO": {
      return {
        ...state,
        logo: {},
        isDone: false
      };
    }

    case "FETCHED_LOGO": {
      return {
        ...state,
        logo: action.payload,
        isDone: true
      };
    }

    case "ERROR_LOGO": {
      return {
        ...state,
        logo: action.payload,
        isDone: true
      };
    }

    default: {
      return state;
    }
  }
};

export default logoReducer;
