import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SplashScreen from "src/components/SplashScreen";
import { setUserData, logout } from "src/actions/accountActions";
import authService from "src/services/authService";

import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();
      if (authService.isAuthenticated()) {
        try {
          const user = await authService.loginInWithToken();
          await dispatch(setUserData(user));
        } catch (error) {
          if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined
          )
            enqueueSnackbar(error.response.data.message, {
              variant: "error"
            });
          setLoading(false);
          history.push("/login");
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
