import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountReducer from "./accountReducer";
import logoReducer from "./logoReducer";
const rootReducer = combineReducers({
  account: accountReducer,
  logo: logoReducer,
  form: formReducer
});

export default rootReducer;
