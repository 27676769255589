import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from "@material-ui/core";
import { logout } from "src/actions/accountActions";
import axios from "src/utils/axiosHelper";
import SpinnerScreen from "src/components/SpinnerScreen";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [openSpinner, setOpenSpinner] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    setOpenSpinner(true);
    try {
      handleClose();
      axios({
        method: "post",
        url: "api/logout"
      })
        .then(async response => {
          await dispatch(logout());
          setOpenSpinner(false);
          history.push("/");
        })
        .catch(err => {
          setOpenSpinner(false);
          if (err.response !== undefined)
            enqueueSnackbar(err.response.data.message, {
              variant: "error"
            });
          else {
            enqueueSnackbar("Something Went Wrong", {
              variant: "error"
            });
          }
        });
    } catch (error) {
      enqueueSnackbar("Unable to logout", {
        variant: "error"
      });
    }
  };

  return (
    <>
      <SpinnerScreen open={openSpinner} />
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account.user.avatar}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${account.user.firstName || "NA"} ${account.user.lastName ||
              "NA"}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
