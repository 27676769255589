import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Popover,
  Typography,
  makeStyles
} from "@material-ui/core";
import deepOrange from "@material-ui/core/colors/deepOrange";
import SpinnerScreen from "src/components/SpinnerScreen";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  popover: {
    width: 220,
    padding: theme.spacing(2)
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const account = useSelector(state => state.account);
  const [isOpen, setOpen] = useState(false);
  const [openSpinner, setOpenSpinner] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SpinnerScreen open={openSpinner} />
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account.user.avatar}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${account.user.firstName || "NA"} ${account.user.lastName ||
              "NA"}`}
          </Typography>
        </Hidden>
      </Box>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography color="textSecondary" align="center">{`${account.user
          .email || "NA"}`}</Typography>
        <Typography color="textSecondary" align="center">{`${account.user
          .role[0].roleName || "NA"}`}</Typography>
      </Popover>
    </>
  );
}

export default Account;
