import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles
} from "@material-ui/core";
import Page from "src/components/Page";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: "100%",
    width: 560,
    maxHeight: 300,
    height: "auto"
  }
}));

function ErrorUI(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    setFlag(!flag);
  }, [props]);

  return (
    <Page className={classes.root} title="500: Something went wrong">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? "h4" : "h1"}
          color="textPrimary"
          style={{ marginBottom: 10 }}
        >
          Something went wrong !
        </Typography>

        <Box mt={6} display="flex" justifyContent="center">
          <img
            alt="Under development"
            className={classes.image}
            src="/static/undraw_server_down_s4lk.svg"
          />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              props.handleUiScreen();

              history.goBack();
            }}
          >
            Back to previous screen
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default ErrorUI;
