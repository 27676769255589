import React from "react";
import { useSelector } from "react-redux";
function Logo(props) {
  const { logo } = useSelector(state => state.logo);
  return (
    <img
      alt="Logo"
      src={
        logo && logo.hamburgerLogo
          ? `data:image/png;base64, ${logo.hamburgerLogo}`
          : "https://finezza.in/wp-content/uploads/2019/04/finezza-logo-1.png"
      }
      {...props}
    />
  );
}

export default Logo;
