import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  colors,
  Link,
  List,
  Typography,
  makeStyles
} from "@material-ui/core";
import {
  PieChart as PieChartIcon,
  BarChart2 as FileTextIcon,
  ArrowDownCircle as ArrowDownCircleIcon,
  List as Queue,
  ArrowUpCircle as ArrowUpCircleIcon,
  PlusCircle as PlusCircleIcone,
  Clipboard as ClipboardIcon,
  CreditCard as DrawdownIcon
} from "react-feather";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { permissionCheck } from "src/constants/CommonMethod";

const navConfig = [
  {
    subheader: "Reports",
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/dashboard"
      },
      {
        title: "Reports",
        icon: FileTextIcon,
        href: "/reports"
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    if (item.permission) {
      if (permissionCheck(item.permission)) {
        acc.push(
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            key={key}
            info={item.info}
            title={item.title}
          />
        );
      }
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 300,
    top: 64,
    height: "calc(100% - 64px)"
  },

  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
    backgroundColor: colors.red[600]
  },
  nav: {
    paddingLeft: "30px"
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box height="100%" display="flex" flexDirection="column">
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/dashboard">
              <Avatar alt="User" className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/dashboard"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName || ""} ${user.lastName || ""}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email || ""}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {user && user.role[0] ? user.role[0].roleName : ""}
            </Typography>
          </Box>
        </Box>
        <Divider style={{ height: "0.8px", backgroundColor: "#f5f5f5" }} />
        <Box className={classes.nav}>
          {navConfig.map(config => (
            <List key={config.subheader}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}></Box>
      </Box>
    </PerfectScrollbar>
  );

  return (
    <>
      <Drawer
        anchor="left"
        variant="persistent"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
      >
        {content}
      </Drawer>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
