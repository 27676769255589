/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import rootReducer from "src/reducers";
import { ENABLE_REDUX_LOGGER } from "src/config";
import authService from "src/services/authService";

const loggerMiddleware = createLogger();

const setPermissionMiddleware = store => next => action => {
  if (
    action.type === "@account/login-success" ||
    action.type === "@account/silent-login"
  ) {
    authService.setPermissions(action.payload.user.permissions);
  }
  return next(action);
};

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware];

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  middlewares.push(setPermissionMiddleware);

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
