import axios from "axios";
//commented lines required in future
// require('dotenv').config();
const instance = axios.create({
  // withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL
});
instance.defaults.params = {};

export default instance;
